@mixin arrowBackground($x, $y)
{
    background: url(../img/arrow.png) $x $y no-repeat;
}
@mixin alignMiddle()
{
    position:absolute;
    top:50% !important;
    transform: translateY(-50%);
}
@mixin alignRight()
{
    position:absolute;
    right: 10px !important;
}
@mixin alignLeft()
{
    position:absolute;
    left: 10px !important;
}

#real-carousel {
    margin: 0;
    overflow: hidden;
    position: relative;
    #slides {
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        /* remove the list styles, width : item width * total items */
        ul {
            left: 0;
            top: 0;
            width: 100%;
            list-style: none;
            margin: 0;
            padding: 0;
            /* width of the item, in this case I put 250x250x gif */
            li {
                position: absolute;
                top:50% !important;
                transition: transform 600ms linear;
                transform: translate(-50%, -50%);


                img {
                    width: 100%;
                    height: auto;
                }
            }

        }
    }
    #real-carousel-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        overflow: hidden;
    }
    /* Styling for prev and next buttons */

    #caption {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background-color: rgba(0, 0, 0, 0.4);
        color: white;
        h1 {
            text-align: center;
            color: white;
        }
    }
    a.buttons {
        display: inline-block;
        position: absolute;
        outline: 0;
        z-index: 50;
    }
    a#prev {
        @include alignLeft();
        @include alignMiddle();

        &:hover {

        }
    }
    a#next {
        @include alignMiddle();

        @include alignRight();
        &:hover {

        }
    }
}
@import "arrow_buttons";
