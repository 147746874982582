.arrow-button {
    display:inline-block;
    width:45px;
    height:45px;
    background-color:white;
    border-radius:50%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.9), inset 0 0 10px rgba(0,0,0,0.5);
    position: absolute;
    &:after {
        content: ' ';
        display:inline-block;
        width:0;
        height:0;
        left: 50%;
        top: 50%;
        position: absolute;

    }
    &.right:after {
        border: 12px solid transparent;
        border-left-color: gray;
        transform: translate(-8%,-50%);
    }
    &.left:after {
        border: 12px solid transparent;
        border-right-color: gray;
        transform: translate(-84%,-50%);
    }

    &:hover {
        opacity: 0.7;
    }
    &.right:hover:after {
        border-left-color: darkred;
    }
    &.left:hover:after {
        border-right-color: darkred;
    }
}
