#real-carousel {
  margin: 0;
  overflow: hidden;
  position: relative;
  /* Styling for prev and next buttons */
}
#real-carousel #slides {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  /* remove the list styles, width : item width * total items */
}
#real-carousel #slides ul {
  left: 0;
  top: 0;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  /* width of the item, in this case I put 250x250x gif */
}
#real-carousel #slides ul li {
  position: absolute;
  top: 50% !important;
  transition: transform 600ms linear;
  transform: translate(-50%, -50%);
}
#real-carousel #slides ul li img {
  width: 100%;
  height: auto;
}
#real-carousel #real-carousel-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}
#real-carousel #caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
#real-carousel #caption h1 {
  text-align: center;
  color: white;
}
#real-carousel a.buttons {
  display: inline-block;
  position: absolute;
  outline: 0;
  z-index: 50;
}
#real-carousel a#prev {
  position: absolute;
  left: 10px !important;
  position: absolute;
  top: 50% !important;
  transform: translateY(-50%);
}
#real-carousel a#next {
  position: absolute;
  top: 50% !important;
  transform: translateY(-50%);
  position: absolute;
  right: 10px !important;
}
.arrow-button {
  display: inline-block;
  width: 45px;
  height: 45px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.9), inset 0 0 10px rgba(0, 0, 0, 0.5);
  position: absolute;
}
.arrow-button:after {
  content: " ";
  display: inline-block;
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
  position: absolute;
}
.arrow-button.right:after {
  border: 12px solid transparent;
  border-left-color: gray;
  transform: translate(-8%, -50%);
}
.arrow-button.left:after {
  border: 12px solid transparent;
  border-right-color: gray;
  transform: translate(-84%, -50%);
}
.arrow-button:hover {
  opacity: 0.7;
}
.arrow-button.right:hover:after {
  border-left-color: darkred;
}
.arrow-button.left:hover:after {
  border-right-color: darkred;
}